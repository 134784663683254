import { Component, OnInit, ViewEncapsulation, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { Observable, Subscription, Subject } from 'rxjs';
import { ContextService } from '../context.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { TranslocoService, AvailableLangs } from '@ngneat/transloco';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ApiService } from '../app.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Constants } from '../app.export.constants';

@Component({
    selector: 'layout',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
    Constants = Constants;
    
    @ViewChild('setownermodal', { static: false }) setownermodal: TemplateRef<any>;
    modalOwnerRef: BsModalRef | null;

    public modalRegisterRef: BsModalRef | null;
    public user$: Observable<any>;
    public roles$: Observable<any>;
    public owner$: Observable<any>;
    public linkShow$: Observable<any>;
    public linkHub$: Observable<any>;
    public oidcAuthority$: Observable<any>;

    public t1 = false;
    public t2 = false;

    public impersonaList: any[] = [];
    public selImpersona: any = null;
    private searchTerms$ = new Subject<string>();

    public allLanguages: AvailableLangs;
    public currentLang: string;
    private subscription: Subscription;

    public isConduttoreModalShown = false;
    public isCompletaPropModalShown = false;
    public passwordRedirectUrl: string;
    public operationsInExecution: boolean = false;

    public registration = { Email: null, Password: null, PageLanguage: null };

    public getYear() {
        return new Date().getFullYear();
    }

    constructor(
        private translocoService: TranslocoService,
        private contextService: ContextService,
        private oidcSecurityService: OidcSecurityService,
        private modalService: BsModalService,
        private router: Router,
        private apiService: ApiService,
        private toastr: ToastrService,
    ) {
        this.allLanguages = this.translocoService.getAvailableLangs();
        this.subscription = this.translocoService.langChanges$.subscribe(lang => this.currentLang = lang.substring(0, 2).toUpperCase());
    }

    public canImpersonate(roles) {
        return roles.some(u => u.IsSelected);
    }

    public canConductDogs(owner) {
        return owner.IsIscrittoAlboAddestratori
            || owner.IsConduttoreCoppieMute
            || owner.IsConduttore;
    }

    public ngOnInit() {
        this.passwordRedirectUrl = window.location.href + "passwordok";
        this.oidcAuthority$ = this.apiService.get("/Utils/GetPar", { par: "OidcAuthority" });
        this.linkShow$ = this.apiService.get("/Utils/GetPar", { par: "showEndpoint" });
        this.linkHub$ = this.apiService.get("/Utils/GetPar", { par: "hubEndpoint" });
        this.oidcSecurityService.onAuthorizationResult.subscribe(t => console.log("[LayoutComponent] onAuthorizationResult:" + t.authorizationState));
        this.user$ = this.contextService.currentUser$;
        this.roles$ = this.contextService.currentRoles$;
        this.owner$ = this.contextService.currentOwner$; //.pipe(
        //    withLatestFrom(this.user$),
        //    map(([owner, user]) => {
        //        //Questa variabile mi serve per mostrare il popup "sei conduttore?" all'avvio dell'applicazione.
        //        //this.isConduttoreModalShown = (owner && owner.Id) ? !owner.AccessoProveEffettuato : false;
        //        this.isConduttoreModalShown = false;
        //        //per mostrare il popup "Completa proprietario" all'avvio dell'applicazione.
        //        if (user && !owner && !this.operationsInExecution) {
        //            this.roles$.subscribe(roles => {
        //                if (roles.filter(r => r.IsSelected).length == 0) { //Mostro il popup solo se non sono un operatore di backoffice, ossia non ho ruoli
        //                    this.modalOwnerRef = this.modalService.show(this.setownermodal, { class: 'modal-owner-ref' });
        //                }
        //            });
        //        }
        //    })
        //);

        //Questo giro con lo switchmap mi serve per evitare che ricerche pi� vecchie, se magari finiscono dopo, si sovrappongano a quelle pi� nuove
        this.searchTerms$.pipe(switchMap((term: string) => {
            return this.apiService.get("/Users/SearchUsersProve", { searchString: term });
        })).subscribe(ret => {
            if (!ret.ex) {
                this.impersonaList = ret.dto;
            }
        });
    }

    public getChromeVersion() {
        let raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
        return raw ? parseInt(raw[2], 10) : 0;
    }

    public customSearchNgSelect(term: string, item: any): boolean {
        //Dato che c'� gi� la ricerca dinamica lato server, questa � disabilitata
        return true;
    }

    public onSearchImpersona(event) {
        if (event.term && event.term.length > 2) {
            this.searchTerms$.next(event.term);
        }
    }

    public logoff() {
        this.operationsInExecution = true;
        this.oidcSecurityService.logoff();
    }

    public login() {
        this.oidcSecurityService.authorize();
    }

    public setConduttore(idProprietario, selezione) {
        this.apiService.get("Users/SetConduttore", { idProprietario: idProprietario, selezione: selezione }).subscribe(ret => {
            if (!ret.ex) {
                this.contextService.updateOwner(ret.dto);
                this.toastr.success(this.translocoService.translate('fe.label.savesuccessful'), this.translocoService.translate('fe.generic.operation.completed'));
            }
        });
    }

    public buttonClicked = false;
    public register() {
        this.buttonClicked = true;

        this.registration.PageLanguage = this.translocoService.getDefaultLang();
        this.apiService.post("Utils/Register", this.registration).subscribe(err => {
            this.modalRegisterRef.hide();
            if (err.dto) {
                this.toastr.warning(this.translocoService.translate(err.dto), this.translocoService.translate('fe.generic.warning'), { disableTimeOut: true, closeButton: true });
            } else {
                this.toastr.success(this.translocoService.translate('fe.label.registersuccessful'), this.translocoService.translate('fe.generic.operation.completed'));
            }
            this.buttonClicked = false;
        });
    }

    public setLang(l: string) {
        localStorage.setItem("activeLang", l);
        this.translocoService.setActiveLang(l);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    showModalRegister(t: TemplateRef<any>) {
        this.modalRegisterRef = this.modalService.show(t, { ignoreBackdropClick: true, class: 'modal-lg modal-register-ref' });
    }

    impersonate(event) {
        this.operationsInExecution = true;
        let data = {};
        data["acr_values"] = "Impersonate:" + event.Id;
        this.oidcSecurityService.setCustomRequestParameters(data);
        this.oidcSecurityService.authorize();
    }

    removeImpersonation() {
        this.operationsInExecution = true;
        this.oidcSecurityService.setCustomRequestParameters({});
        this.oidcSecurityService.authorize();
    }

}
