import { AfterViewInit, Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ApiService, BaseFunctions } from '../app.service';
import { Observable } from 'rxjs';
import { ContextService } from '../context.service';
import { filter, map, switchMap } from 'rxjs/operators';
import { Router } from "@angular/router";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { OidcSecurityService } from 'angular-auth-oidc-client';

declare var $;

@Component({
    selector: 'app-enrollments',
    templateUrl: './enrollments.component.html',
})
export class EnrollmentsComponent implements OnInit, AfterViewInit {
    public eventiDto$: Observable<any>;
    public gpcodiciesra$: Observable<any>;
    public listasocspec$: Observable<any>;
    public lang$: Observable<any>;
    public codProtSudd = [];

    public filterCodEsRa: string = "";
    public filterCodProtSudd: string = "";
    public filterSocSpec: string = "";

    public user: any;

    @ViewChild('modalSpunta', { static: false }) modalSpunta: TemplateRef<any>;
    public modalSpuntaRef: BsModalRef | null;
    public spuntaProp: any = null;

    constructor(
        private apiService: ApiService,
        private contextService: ContextService,
        private base: BaseFunctions,
        private router: Router,
        private modalService: BsModalService,
        private oidcSecurityService: OidcSecurityService
    ) { }

    ngAfterViewInit(): void {
        if (localStorage["idEventoDaAprire"]) {
            if (Number(localStorage["idEventoDaAprire"])) {
                let temp = localStorage["idEventoDaAprire"];
                localStorage.removeItem("idEventoDaAprire");
                this.router.navigate(['/results/' + temp]);
            } else {
                localStorage.removeItem("idEventoDaAprire");
            }
        }

        this.contextService.currentLang$.pipe(

            filter(() => this.oidcSecurityService.getToken() != ''),
            switchMap((lang) => this.apiService.get("Users/GetSpuntaProp", { lang: lang }))).subscribe(
            (zz) => {
                if (!zz.ex) {
                    if (zz.dto) {
                        this.spuntaProp = zz.dto;
                        this.showModalSpunta();
                    }
                }
            }
        );
    }

    public async ngOnInit() {
        this.lang$ = this.contextService.currentLang$;
        this.eventiDto$ = this.contextService.currentLang$.pipe(switchMap(lang => this.apiService.get("/Events/GetAllGpEventiDto", { pageLanguage: lang, mode: "ALL" })));
        this.gpcodiciesra$ = this.contextService.currentLang$.pipe(switchMap(lang => this.apiService.get("/Utils/GetAllGpCodiciEsRa", { pageLanguage: lang })), map(z => z.dto));
        this.listasocspec$ = this.contextService.currentLang$.pipe(switchMap(lang => this.apiService.get("/Utils/GetAllSocSpec", { pageLanguage: lang })), map(z => z.dto));

        this.contextService.currentUser$.subscribe(u => {
            this.user = u;
        });

        // ANIMAZIONI GRAFICHE
        var offsetTop = 197;
        var scrollPosition;
        // bootstrap navlinks scrollspy
        $('body').scrollspy({ target: '#NavEventiList', offset: offsetTop });
        // section detect active on scroll
        window.onscroll = function (event) {

            scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;

            if ($("#EventiList").length > 0 && $("#EventiList")[0].offsetTop - offsetTop <= scrollPosition && $("#EventiList")[0].offsetTop + $("#EventiList")[0].offsetHeight - offsetTop > scrollPosition) {
                $("body").attr('spy-section', 'activeEventiList');
            }
            else {
                $("body").removeAttr('spy-section');
            }

            $("[scroll-detect]").each(function () {
                var currSec = $(this);
                if (currSec[0].offsetTop <= scrollPosition && currSec[0].offsetTop + currSec[0].offsetHeight > scrollPosition) {
                    $("[scroll-detect]").removeClass("active");
                    currSec.addClass("active");
                }
                else {
                    currSec.removeClass("active");
                }
            });
        };
        // anchor links smooth scroll
        $(document).on('click', 'a[href^="#"]', function (event) {
            event.preventDefault();
            let currentPos = $(window).scrollTop();
            let destinetion = $($.attr(this, 'href')).offset().top;
            if (destinetion == currentPos) { }
            else if (destinetion > currentPos) {
                //going down
                if ($(window).width() >= 992) {
                    $('html, body').animate({
                        scrollTop: destinetion - 95
                    }, 500);
                }
                else if ($(window).width() >= 768) {
                    $('html, body').animate({
                        scrollTop: destinetion - 50
                    }, 500);
                }
                else {
                    $('html, body').animate({
                        scrollTop: destinetion
                    }, 500);
                }
            }
            else {
                //going up
                if ($(window).width() >= 992) {
                    $('html, body').animate({
                        scrollTop: destinetion - 185
                    }, 500);
                }
                else if ($(window).width() >= 768) {
                    $('html, body').animate({
                        scrollTop: destinetion - 50
                    }, 500);
                }
                else {
                    $('html, body').animate({
                        scrollTop: destinetion
                    }, 500);
                }
            }
        });
        // END - animazioni grafiche
    }

    public showModalSpunta() {
        this.modalSpuntaRef = this.modalService.show(this.modalSpunta, { ignoreBackdropClick: true, class: 'modal-lg modal-select-dog-ref' });
    }

    public spunta() {
        this.apiService.get("Users/SaveSpuntaProp", { spuntaId: this.spuntaProp.Id }).subscribe((ret) => {
            this.modalSpuntaRef.hide();
        });
    }

    public routerNavigateTo(url, id) {
        this.router.navigate([url, id])
    }

    public getCodProtSudd(lang) {
        this.filterCodProtSudd = "";
        if (this.base.isNullOrEmpty(this.filterCodEsRa)) {
            this.codProtSudd = [];
        } else {
            this.apiService.get("/Utils/GetUsedGpCodiciProtSudd", { codEsRa: this.filterCodEsRa, pageLanguage: lang }).subscribe(ret => {
                this.codProtSudd = ret.dto.filter(z => !this.base.isNullOrEmpty(z.Description));
            });
        }
    }

    // TO REMOVE
    getRandomColor2() {
        var length = 6;
        var chars = '0123456789ABCDEF';
        var hex = '#';
        while (length--) hex += chars[(Math.random() * 16) | 0];
        return hex;
    }

    getRandomColor() {
        var color = Math.floor(0x1000000 * Math.random()).toString(16);
        return '#' + ('000000' + color).slice(-6);
    }
    // END - to remove
}
