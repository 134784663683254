import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { ContextService } from '../context.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { TranslocoService } from '@ngneat/transloco';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ApiService, BaseFunctions } from '../app.service';
import { ToastrService } from 'ngx-toastr';
import { withLatestFrom, map } from 'rxjs/operators';
import { Constants } from '../app.export.constants';

@Component({
    selector: 'az-owner',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './owner.component.html',
    styleUrls: ['./owner.component.css']
})
export class OwnerComponent implements OnInit {

    Constants = Constants;
    public lang$: Observable<any>;
    public nazioni$: Observable<any>;
    public province$: Observable<any>;

    public cfpiOccupato = true;
    public datiEnciOTributiOk = false;
    public owner: any;
    public inAccesso = true;

    public titoli = { "it-IT": ['Sig.', 'Sig.ra', 'Spett.le'], "en-GB": ['Mr.', 'Mrs.', 'Est.'] };

    constructor(
        private baseFunctions: BaseFunctions,
        private translocoService: TranslocoService,
        private contextService: ContextService,
        private oidcSecurityService: OidcSecurityService,
        private modalService: BsModalService,
        private apiService: ApiService,
        private toastr: ToastrService) {
    }

    public async ngOnInit() {
        this.inAccesso = true;
        this.lang$ = this.contextService.currentLang$;
        this.province$ = this.apiService.get("Utils/GetProvince", null);
        this.nazioni$ = this.apiService.get("Utils/GetNazioni", null);
        this.contextService.currentOwner$.pipe(
            withLatestFrom(this.contextService.currentUser$),
            map(([owner, user]) => {
                if (owner) {
                    return owner;
                } else if (user) {
                    let temp = {};
                    temp['IdUser'] = user.sub;
                    temp['Email'] = user.email;
                    temp['Id'] = 0;
                    temp['IdNazione'] = 45;
                    temp['AccessoProveEffettuato'] = true;
                    temp['IsConduttore'] = false;
                    temp['IsIscrittoAlboAddestratori'] = false;
                    temp['IsConduttoreCae'] = false;
                    temp['IsConduttoreCoppieMute'] = false;
                    return temp;
                }
            })
        ).subscribe(ret => {
            this.owner = ret;
            if (this.owner && this.owner.CodFiscale && this.owner.IdNazione == 45 && this.inAccesso) {
                this.lang$.subscribe(l => {
                    this.getDatiProp(this.owner.CodFiscale, l);
                });
            } else {
                this.inAccesso = false;
            }
        });
    }

    public eccezioneReperitoNomeVuoto = false;
    public eccezioneReperitoCognomeVuoto = false;
    public eccezioneReperitoProvinciaVuota = false;

    public getDatiProp(cf: string, lang: string) {
        this.eccezioneReperitoNomeVuoto = false;
        this.eccezioneReperitoCognomeVuoto = false;
        this.eccezioneReperitoProvinciaVuota = false;
        this.cfpiOccupato = true;
        if ((cf.length == 11 && /^\d+$/.test(cf)) || cf.length == 16) { //se � P.IVA o C.FISC
            this.apiService.get("Users/CheckCfPiOccupato", { cf: cf }).subscribe(cfpiOccupato => {
                this.cfpiOccupato = cfpiOccupato.dto;
                if (cfpiOccupato.dto) {
                    this.toastr.warning(this.translocoService.translate('fe.label.owner.idgiapresente'), this.translocoService.translate('fe.generic.warning'), { disableTimeOut: true, closeButton: true });
                    this.inAccesso = false;
                } else {
                    this.apiService.post("Users/TryGetOwnerExt", { Owner: this.owner }).subscribe(ownerDatiEnciTributi => {
                        this.inAccesso = false;
                        if (ownerDatiEnciTributi.dto && !ownerDatiEnciTributi.dto.ErroreReperimentoDati) {
                            this.owner = this.baseFunctions.sanitizeDate(ownerDatiEnciTributi.dto);
                            this.datiEnciOTributiOk = true;
                            this.eccezioneReperitoNomeVuoto = this.baseFunctions.isNullOrEmpty(ownerDatiEnciTributi.dto.Nome);
                            this.eccezioneReperitoCognomeVuoto = this.baseFunctions.isNullOrEmpty(ownerDatiEnciTributi.dto.Cognome);
                            this.eccezioneReperitoProvinciaVuota = this.baseFunctions.isNullOrEmpty(ownerDatiEnciTributi.dto.Provincia);

                            //GF 4 Sett 2020: chiesto di ripristinare il salvataggio che gi� c'era ma era stato chiesto di toglierlo
                            if (!this.saving) {
                                this.saving = true;
                                this.owner['PageLanguage'] = lang;
                                this.apiService.post("Users/SaveOwner", this.owner).subscribe(ret => {
                                    this.saving = false;
                                    if (!ret.ex) {
                                        this.toastr.success(this.translocoService.translate('fe.label.owner.datireperiti') + '\n' + this.translocoService.translate('fe.label.owner.saveremember'), this.translocoService.translate('fe.generic.operation.completed'));
                                        this.contextService.updateOwner(this.baseFunctions.sanitizeDate(ret.dto));
                                    }
                                });
                            }
                        } else {
                            this.toastr.warning(this.translocoService.translate('fe.label.owner.datinonreperiti'), this.translocoService.translate('fe.generic.warning'), { disableTimeOut: true, closeButton: true });
                        }
                    })
                }
            })
        }
    }

    //public onChangeIsIscrittoAlboAddestratori() {
    //    if (this.owner.IsIscrittoAlboAddestratori) {
    //        this.apiService.get("Users/TryCheckAddestratoreHandler", { nome: this.owner.Nome, cognome: this.owner.Cognome, codicefiscale: this.owner.CodFiscale }).subscribe(ret => {
    //            if (!ret.dto) {
    //                this.toastr.warning(this.translocoService.translate('fe.label.owner.notbreeder'), this.translocoService.translate('fe.generic.warning'), { disableTimeOut: true, closeButton: true });
    //            }
    //        });
    //    }
    //}

    //public warningIsIscrittoAlboAddestratori() {
    //    if (!this.owner.CodFiscale || !this.owner.Nome || !this.owner.Cognome) {
    //        this.toastr.warning(this.translocoService.translate('fe.label.owner.compilarecampi'), this.translocoService.translate('fe.generic.warning'), { disableTimeOut: true, closeButton: true });
    //    }
    //}

    public warningIsIscrittoAlboAddestratori() {
        event.preventDefault();
        if (!this.owner.CodFiscale || !this.owner.Nome || !this.owner.Cognome) {
            this.toastr.warning(this.translocoService.translate('fe.label.owner.compilarecampi'), this.translocoService.translate('fe.generic.warning'), { disableTimeOut: true, closeButton: true });
        } else {
            if (!this.owner.IsIscrittoAlboAddestratori) {

                this.owner.IsIscrittoAlboAddestratori = false;

                this.apiService.get("Users/TryCheckAddestratoreHandler", { nome: this.owner.Nome, cognome: this.owner.Cognome, codicefiscale: this.owner.CodFiscale }).subscribe(ret => {
                    if (!ret.dto) {
                        this.toastr.warning(this.translocoService.translate('fe.label.owner.notbreeder'), this.translocoService.translate('fe.generic.warning'), { disableTimeOut: true, closeButton: true });
                    } else {
                        this.owner.IsIscrittoAlboAddestratori = true;
                        this.owner.IsConduttore = false;
                        this.owner.DataInizioConduttoreForzatoAdmin = null;
                        this.owner.DataFineConduttoreForzatoAdmin = null;
                    }
                });
            }
        }
    }

    public saving = false;
    public buttonClicked = false;
    public saveOwner(owner, lang) {
        this.buttonClicked = true;
        owner.PageLanguage = lang;
        
        if (!this.saving) {
            this.saving = true;
            this.apiService.post("Users/SaveOwner", owner).subscribe(ret => {
                this.saving = false;
                if (!ret.ex) {
                    this.contextService.updateOwner(this.baseFunctions.sanitizeDate(ret.dto));
                    this.toastr.success(this.translocoService.translate('fe.label.owner.savesuccessful'), this.translocoService.translate('fe.generic.operation.completed'));
                }
                this.buttonClicked = false;
            });
        }
    }

    public setDateCondForzato(impersonatore) {
        if (this.owner.IsConduttore) {
            this.owner.UtenteConduttoreForzatoAdmin = impersonatore;
            this.owner.DataInizioConduttoreForzatoAdmin = this.baseFunctions.getFormattedDate(new Date());
            this.owner.DataFineConduttoreForzatoAdmin = new Date();
            this.owner.DataFineConduttoreForzatoAdmin.setFullYear(this.owner.DataFineConduttoreForzatoAdmin.getFullYear() + 1);
            this.owner.DataFineConduttoreForzatoAdmin = this.baseFunctions.getFormattedDate(this.owner.DataFineConduttoreForzatoAdmin);
        } else {
            this.owner.DataInizioConduttoreForzatoAdmin = null;
            this.owner.DataFineConduttoreForzatoAdmin = null;
        }
    }
}