import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';

import { AuthGuard } from './auth/auth.guard';
import { ToastrModule } from 'ngx-toastr';
import { registerLocaleData, CommonModule } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import localeEn from '@angular/common/locales/en';
import { AuthModule, OidcConfigService, OidcSecurityService, ConfigResult, OpenIdConfiguration } from 'angular-auth-oidc-client';
import { routes } from './app.routing';
import { environment } from '../environments/environment';
import { translocoLoader } from './transloco.loader';
import { TranslocoModule, TRANSLOCO_CONFIG, TranslocoConfig, TranslocoService } from '@ngneat/transloco';
import { ApiService } from './app.service';
import { AuthInterceptor } from './auth/auth.interceptor';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { CatalogsComponent } from './catalogs/catalogs.component';
import { HomeComponent } from './home/home.component';
import { EnrollmentsComponent } from './enrollments/enrollments.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { ResultsComponent } from './results/results.component';
import { InformationsComponent } from './informations/informations.component';
import { WarningsComponent } from './warnings/warnings.component';
import { ENCI_LogoComponent } from './ENCI_globals/ENCI_Logo/ENCI_Logo.component';
import { FCI_LogoComponent } from './ENCI_globals/FCI_Logo/FCI_Logo.component';
import { ErrorComponent } from './error/error.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { ENCI_WarningsComponent } from './ENCI_globals/ENCI_Warnings/ENCI_Warnings.component';
import { SubtrialsComponent } from './subtrials/subtrials.component';
import { ENCI_CalendarSectionComponent } from './ENCI_globals/ENCI_CalendarSection/ENCI_CalendarSection.component';
import { FilterPipe } from './shared/filterpipe';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { PopoverModule } from 'ngx-bootstrap/popover'
import { ConfEmailOkComponent } from './confemailok/confemailok.component';
import { ConfEmailErrorComponent } from './confemailerror/confemailerror.component';
import { OwnerComponent } from './owner/owner.component';
import { MyDogsComponent } from './mydogs/mydogs.component';
import { DogListItemComponent } from './shared/doglistitem/doglistitem.component';
import { EditDogComponent } from './shared/editdog/editdog.component';
import { RazzaFilterPipe } from './shared/razzafilterpipe';
import { LocaleDatePipe } from './shared/localedate.pipe';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { InscriptionsComponent } from './inscriptions/inscriptions.component';
import { InscriptionListItemComponent } from './shared/inscriptionlistitem/inscriptionlistitem.component';
import { GroupFilterPipe } from './shared/groupfilterpipe';
//import { GoogleMapsModule } from '@angular/google-maps';
import { AgmCoreModule } from '@agm/core';
import { ColumnFilterPipe } from './shared/columnfilterpipe';
import { DataGiornoFilterPipe } from './shared/datagiornofilterpipe';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ImageUploadWithPreviewComponent } from './shared/uploadwithpreview/uploadwithpreview.component';
import { PasswordOkComponent } from './passwordok/passwordok.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { EventDetailsLightComponent } from './shared/eventdetailslight/eventdetailslight.component';
import { EventDetailsModalsComponent } from './shared/eventdetailsmodals/eventdetailsmodals.component';
import { ModalService } from './app.modalservice';
import { EventDetailsResultComponent } from './shared/eventdetailsresult/eventdetailsresult.component';
import { CountDownComponent } from './shared/count-down/count-down.component';
import { EditRaggComponent } from './shared/editragg/editragg.component';
import { SingleRaggComponent } from 'src/app/shared/singleragg/singleragg.component';
import { AngularDraggableModule } from 'angular2-draggable';
import { RazzaCodEnciFilterPipe } from './shared/razzacodencifilterpipe';
import { RequisitiComponent } from 'src/app/shared/requisiti/requisiti.component';
import { RequisitiItemComponent } from 'src/app/shared/requisitiitem/requisitiitem.component';

registerLocaleData(localeIt, 'it');
registerLocaleData(localeEn, 'en');

export function loadConfig(oidcConfigService: OidcConfigService) {
    return () => oidcConfigService.load('assets/auth.config.json');
}

export function preloadLanguages(apiService: ApiService, transloco: TranslocoService) {
    return () => {
        return apiService.get("/Utils/GetGpLanguages", null).toPromise().then(result => {
            const traslocoLanguages = (<any[]>result.dto.allLanguages).map(t => ({ id: t.LanguageCulture, label: t.Name }));
            const defaultLanguage = <string>result.dto.defaultLanguage;
            transloco.setAvailableLangs(traslocoLanguages);
            transloco.setDefaultLang(defaultLanguage);
            const activeLang = localStorage.getItem("activeLang");
            transloco.setActiveLang(activeLang ? activeLang : defaultLanguage);
            return transloco.load(defaultLanguage).toPromise();
        });
    }
}

@NgModule({
    declarations: [
        AppComponent,
        LayoutComponent,
        HomeComponent,
        EnrollmentsComponent,
        StatisticsComponent,
        CatalogsComponent,
        ResultsComponent,
        InformationsComponent,
        WarningsComponent,
        ENCI_LogoComponent,
        FCI_LogoComponent,
        NavMenuComponent,
        ErrorComponent,
        SubtrialsComponent,
        ENCI_WarningsComponent,
        ENCI_CalendarSectionComponent,
        ConfEmailOkComponent,
        ConfEmailErrorComponent,
        OwnerComponent,
        MyDogsComponent,
        DogListItemComponent,
        EditRaggComponent,
        SingleRaggComponent,
        UnauthorizedComponent,
        EditDogComponent,
        InscriptionsComponent,
        InscriptionListItemComponent,
        FilterPipe,
        RazzaFilterPipe,
        LocaleDatePipe,
        GroupFilterPipe,
        ColumnFilterPipe,
        DataGiornoFilterPipe,
        RazzaCodEnciFilterPipe,
        EventDetailsLightComponent,
        EventDetailsResultComponent,
        EventDetailsModalsComponent,
        ImageUploadWithPreviewComponent,
        PasswordOkComponent,
        CountDownComponent,
        RequisitiComponent,
        RequisitiItemComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        AuthModule.forRoot(),
        ToastrModule.forRoot(),
        RouterModule.forRoot(
            routes, { scrollPositionRestoration: 'enabled' }
        ),
        TranslocoModule,
        TranslocoLocaleModule.init({
            localeConfig: {
                global: {
                    date: {
                        dateStyle: 'medium'
                    }
                },
                localeBased: {}
            }
        }),
        ModalModule.forRoot(),
        ButtonsModule.forRoot(),
        PopoverModule.forRoot(),
        CommonModule,
        //SCP PER DEV: 'AIzaSyADb6EON3nuR8vhfG9zEdwdZTcZRq-_5hA'
        // PROD 'AIzaSyCstwinB9HsRT98NRoQToeOQ7DTBemteEo'
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyCstwinB9HsRT98NRoQToeOQ7DTBemteEo'
        }),
        CollapseModule.forRoot(),
        NgSelectModule,
        AngularDraggableModule
    ],
    providers: [
        OidcConfigService,
        ApiService,
        {
            provide: APP_INITIALIZER,
            useFactory: loadConfig,
            deps: [OidcConfigService],
            multi: true,
        },
        {
            provide: APP_INITIALIZER, multi: true,
            useFactory: preloadLanguages,
            deps: [ApiService, TranslocoService, ModalService]
        },
        AuthGuard,
        { provide: LOCALE_ID, useValue: 'it-IT' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        translocoLoader,
        {
            provide: TRANSLOCO_CONFIG,
            useValue: {
                reRenderOnLangChange: true,
                prodMode: environment.production
            } as TranslocoConfig
        }
    ],
    exports: [
        GroupFilterPipe,
        LocaleDatePipe,
        FilterPipe,
        RazzaFilterPipe,
        ColumnFilterPipe,
        DataGiornoFilterPipe,
        RazzaCodEnciFilterPipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(private oidcSecurityService: OidcSecurityService, private oidcConfigService: OidcConfigService) {

        this.oidcConfigService.onConfigurationLoaded.subscribe((configResult: ConfigResult) => {
            // Use the configResult to set the configurations
            const config: OpenIdConfiguration = {
                post_logout_redirect_uri: window.location.origin + '/',
                stsServer: configResult.customConfig.stsServer,
                redirect_url: window.location.origin + '/',
                client_id: 'gestprovefe',
                scope: 'openid profile email offline_access roles impersonation gestprovefe_api',
                response_type: 'code',
                silent_renew: true,
                use_refresh_token: true,
                auto_userinfo: true,
                start_checksession: true,
                log_console_debug_active: !environment.production,
            };

            this.oidcSecurityService.setupModule(config, configResult.authWellknownEndpoints);
        });
    }
}
